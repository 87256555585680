import { format } from "date-fns"

import {
  IEstimate,
  LineItemEntityType,
  SubscriptionStatus,
  TEstimate,
  UTCSeconds,
} from "src/components/Account/types"
import {
  IAvailablePlan,
  ISubscriptionEstimateResponse,
  TCurrentSubscription,
} from "src/data/billing/types/billingTypes"
import { TSubscriptionEstimates } from "src/data/subscription/types/subscriptionTypes"
import { PLAN, TPlan } from "src/data/user/user"

export interface PlanWithCostEstimate {
  availablePlan: IAvailablePlan
  cost: {
    plan: {
      quantity?: number
      unitPrice?: number
      total?: number
    }
    totalPrice: number
    estimate: ISubscriptionEstimateResponse | TSubscriptionEstimates
  }
}

export enum PlanChangeTypeV2 {
  UNCHANGED = "UNCHANGED",
  REACTIVATE = "REACTIVATE",
  CREATE = "CREATE",
  INVOICE_IMMEDIATELY = "INVOICE_IMMEDIATELY",
  INVOICE_AT_TERM_END = "INVOICE_AT_TERM_END",
}

export function centsToDollarPerMonth(unitPrice: number) {
  // minimize number of division to avoid floating point error
  return unitPrice / (12 * 100)
}

// TODO PRD-402: replace this with a proper change based on plan to translations
export function planToCopy(plan: TCurrentSubscription["plan"] | TPlan) {
  if (plan === "pro") {
    return "Pro"
  } else if (plan === "pro_plus") {
    return "Pro+"
  } else {
    return "Standard"
  }
}

export function getUpdateOptions({
  planChangeType,
  hasCouponIds,
}: {
  planChangeType: PlanChangeTypeV2
  hasCouponIds?: boolean
}) {
  const reactivateOptions = {
    reactivate: true,
    end_of_term: false,
    invoice_immediately: true,
    force_term_reset: true,
  }

  const updateOptions = ({
    endOfTerm,
    forceTermReset = false,
  }: {
    endOfTerm: boolean
    forceTermReset: boolean
  }) => ({
    reactivate: false,
    end_of_term: endOfTerm,
    invoice_immediately: !endOfTerm,
    force_term_reset: forceTermReset,
  })

  switch (planChangeType) {
    case PlanChangeTypeV2.REACTIVATE:
      return reactivateOptions
    case PlanChangeTypeV2.INVOICE_IMMEDIATELY:
      // If a coupon is added, force reset the term to make sure that the
      // discount is applied over a full term, as opposed to just the pro-rated
      // part that is left.
      return updateOptions({ endOfTerm: false, forceTermReset: !!hasCouponIds })
    case PlanChangeTypeV2.INVOICE_AT_TERM_END:
      return updateOptions({ endOfTerm: true, forceTermReset: false })
    default:
      throw new Error(
        `Unsupported plan change type, it must be one of ${PlanChangeTypeV2.REACTIVATE}, ${PlanChangeTypeV2.INVOICE_IMMEDIATELY}, ${PlanChangeTypeV2.INVOICE_AT_TERM_END} but was ${planChangeType}`
      )
  }
}

export function createPlansWithCostEstimate({
  availablePlans,
  estimates,
}: {
  availablePlans: IAvailablePlan[]
  estimates: ISubscriptionEstimateResponse[] | TSubscriptionEstimates[]
}): PlanWithCostEstimate[] {
  const planIds = availablePlans.map((plan) => plan.plan_id)
  const planIdToAvailablePlan = availablePlans.reduce<{
    [key: string]: IAvailablePlan
  }>(
    (accumulator, plan) => ({
      ...accumulator,
      [plan.plan_id]: plan,
    }),
    {}
  )
  const entityIdToEstimate = estimates.reduce<{
    [key: string]: PlanWithCostEstimate["cost"]
  }>((accumulator, estimate) => {
    const planLineItem = estimate.estimate.invoice_estimate?.line_items?.find(
      (item) => item.entity_type === LineItemEntityType.PLAN
    )

    if (planLineItem && planLineItem.entity_id) {
      return {
        ...accumulator,
        [planLineItem.entity_id]: {
          plan: {
            unitPrice: planLineItem.unit_amount,
            quantity: planLineItem.quantity,
            total: planLineItem.amount,
          },
          totalPrice: estimate?.estimate.invoice_estimate?.total ?? 0,
          estimate: estimate,
        },
      }
    } else {
      throw new Error(
        "Could not find any plan Item or plan Item with entity_id in estimate"
      )
    }
  }, {})

  return planIds.map((planId) => ({
    availablePlan: planIdToAvailablePlan[planId],
    cost: entityIdToEstimate[planId],
  }))
}

export function stringToPlan(plan: string | undefined): TPlan {
  if (plan === "pro") {
    return PLAN.pro
  } else if (plan === "pro_plus") {
    return PLAN.pro_plus
  } else if (plan === "standard") {
    return PLAN.standard
  }
  return PLAN.starter
}

export function calculatePlanChangeType({
  subscription,
  planId,
  currentPlan,
  newPlan,
  isLegacyPlan,
}: {
  subscription:
    | Pick<
        TCurrentSubscription,
        "subscription_status" | "billing_period" | "plan_id"
      >
    | undefined
  planId: string | undefined
  currentPlan?: TPlan
  newPlan?: TPlan
  isLegacyPlan: boolean
}) {
  if (subscription?.plan_id === planId || !newPlan) {
    return PlanChangeTypeV2.UNCHANGED
  } else if (
    subscription?.subscription_status === SubscriptionStatus.CANCELLED
  ) {
    //reactivate
    return PlanChangeTypeV2.REACTIVATE
  } else if (currentPlan === "starter" || !currentPlan) {
    return PlanChangeTypeV2.CREATE
  } else if (isLegacyPlan && subscription?.billing_period === "month") {
    // if current plan is pro -> standard == downgrade end of term false
    // if current plan is standard -> pro == upgrade end of term false
    // else == switch to annual end of term false
    return PlanChangeTypeV2.INVOICE_IMMEDIATELY
  } else if (isLegacyPlan) {
    if (currentPlan === "pro" && newPlan === "standard") {
      return PlanChangeTypeV2.INVOICE_AT_TERM_END
    }
    // if current plan is pro -> new standard == downgrade, end of term false
    // if current plan is pro -> new pro == upgrade?, end of term false
    // if current plan is standard -> new pro == upgrade, end of term false
    // if current plan is standard -> new standard == upgrade?, end of term false
    return PlanChangeTypeV2.INVOICE_IMMEDIATELY
  } else {
    // if current plan is pro+ -> standard or pro = downgrade
    if (
      currentPlan === "pro_plus" &&
      (newPlan === "pro" || newPlan === "standard")
    ) {
      return PlanChangeTypeV2.INVOICE_AT_TERM_END
    }

    // if current plan is new pro -> new standard = downgrade
    if (currentPlan === "pro" && newPlan === "standard") {
      return PlanChangeTypeV2.INVOICE_AT_TERM_END
    }

    // if current plan is standard -> pro or pro+ = upgrade
    if (
      currentPlan === "standard" &&
      (newPlan === "pro" || newPlan === "pro_plus")
    ) {
      return PlanChangeTypeV2.INVOICE_IMMEDIATELY
    }

    // if current plan is pro -> pro+ = upgrade
    if (currentPlan === "pro" && newPlan === "pro_plus") {
      return PlanChangeTypeV2.INVOICE_IMMEDIATELY
    }
  }
}

export function formatUTCSecondsDate(date?: UTCSeconds) {
  return date ? format(new Date(Number(date) * 1000), "MMM do yyyy") : null
}

export function formatUTCStringDate(date?: string) {
  return date ? format(new Date(date), "MMM do yyyy") : null
}

export function getCouponIdsFromEstimate(
  estimate: IEstimate | TEstimate | undefined
) {
  if (!estimate) {
    return undefined
  }

  return (
    estimate.invoice_estimate?.discounts
      ?.filter((discount) =>
        ["document_level_coupon", "item_level_coupon"].includes(
          discount?.entity_type || ""
        )
      )
      ?.map((discount) => discount.entity_id)
      /** For all discounts of type document_level_coupon or item_level_coupon,
      chargebee returns an entity_id, which corresponds to the id of the
      coupon. This typeguard makes typescript understand this. */
      ?.filter((entityId): entityId is string => entityId !== undefined)
  )
}
